import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppLayout from '../../../layouts/AppLayout/AppLayout';
import { Helmet } from 'react-helmet';
import { NioSection, NioButton } from '../../../components';

function ThanksPage() {
  return (
    <AppLayout title="Thank You" rootClass="layout-1">
      <Helmet>
        <title>Thank You</title>
        <meta
          name="description"
          content="We will reach you soon to connect with you."
        />
        
      </Helmet>
      <div className="d-flex flex-column min-vh-100">
        <div style={{ flexGrow: 1 }}>
          <NioSection className="pt-120 pt-xl-180 overflow-hidden pb-0">
            <Row className="justify-content-center">
              <Col lg={10}>
                <div className="pb-5 pb-lg-7 text-center">
                  <h1 className="mb-4 text-primary">Thank You!</h1>
                  <p className="text-muted">
                    Thank you for booking an appointment. We will reach out to you soon to connect with you.
                  </p>

                  {/* Download Now Button */}
                  <a
                    href="https://www.siteoptimizr.com/5-Conversion-Killers.pdf"
                    download
                  >
                    <NioButton 
                      label="Download Now" 
                      className="btn text-nowrap w-100 text-white"
                      style={{
                        padding: '15px 25px',
                        fontSize: '18px',
                        marginTop: '30px',
                        backgroundColor: '#28a745',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    />
                  </a>

                  {/* Back Home Button */}
                  <a href="/">
                    <NioButton 
                      label="Back Home" 
                      className="btn text-nowrap w-100 text-white"
                      style={{
                        padding: '15px 25px',
                        fontSize: '18px',
                        marginTop: '20px',
                        backgroundColor: '#0062FF',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </NioSection>
        </div>
      </div>
    </AppLayout>
  );
}

export default ThanksPage;