import React from 'react';
import classNames from 'classnames';
import NioBrand from '../../../NioBrand/NioBrand';
export default function HeaderLogo({ className }) {
  const compClasses = classNames({
    "nk-header-logo": true,
    [`${className}`]: className
  });
  return (
    <a href="https://siteoptimizr.com" className={compClasses} style={{ display: 'inline-block' }}>
      <img src="/images/new/Logo.png" alt="SiteOptimizr Logo" style={{ maxWidth: '250px' }} />
    </a>
  );
}