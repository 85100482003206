import React from 'react';
import classNames from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'react-bootstrap';

// config 
import config from '../../data/layout-config';

// custom hooks
import useRouteMatch from '../../hooks/useRouteMatch';

// components
import { NioBrand, NioIcon } from '..';

export default function Footer({ variant = 1, children }) {
  const compClasses = classNames({
    [`${useRouteMatch("/") ? "nk-footer-landing" : "nk-footer"}`]: true,
  });

  const socialIconClasses = classNames({
    "text-bg-primary": !useRouteMatch([
      "/index-kids-course", "/index-crypto-profile", "/index-live-chat-app",
      "/index-bs-management", "/index-language-learning", "/index-collaboration-tool",
      "/index-bs-solution", "/index-bs-expense-tracker", "/index-saas",
      "/index-bs-subscription", "/index-data-driven", "/index-bs-digital"
    ]),
  });

  const brandLinkClasses = classNames({
    "text-primary": !useRouteMatch([
      "/index-kids-course", "/index-crypto-profile", "/index-live-chat-app",
      "/index-bs-management", "/index-language-learning", "/index-collaboration-tool",
      "/index-bs-solution", "/index-bs-expense-tracker", "/index-saas",
      "/index-bs-subscription", "/index-data-driven", "/index-bs-digital"
    ])
  });

  const footerTextClasses = classNames({
    "fs-16": useRouteMatch(["/index-kids-course"]),
    "fw-medium": useRouteMatch(["/index-kids-course"]),
    "text-capitalize": true,
    "text-dark": !useRouteMatch(["/index-crypto-profile"]),
  });

  function filterDataByVariant(variantNumber) {
    return config.filter(item => item.variant === variantNumber);
  }

  const [data] = filterDataByVariant(variant);

  return (
    <footer className={compClasses}>
      {data.footer.variant === 2 ? (
        <section className={compClasses}>
          <div className="nk-footer-top bg-gray">
            <Container>
              <Row className="nk-footer-content justify-content-xl-between">
                <Col md={8} lg={4} xl={4}>
                  <div className="nk-footer-brand pb-5 pb-lg-0">
                    <div className="nk-footer-brand-info mb-4">
                      <div className="nk-footer-logo">
                        <img src="/images/new/Logo.png" alt="Site OptimizR Logo" width={"200px"} />
                      </div>
                      <p>
                      At Site OptimizR, we blend creative strategies with data-driven insights to help you boost your revenue and grow your business effectively.
                      </p>

                      {/* Address Section */}
                      <div className="footer-address" style={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <NioIcon name="map-pin" />
                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}>
                          11334 Boggy Creek Rd, Orlando, FL 32824
                        </span>
                      </div>

                    </div>
                  </div>
                </Col>
                <Col lg={8} xl={6}>
                  <Row className="justify-content-between">
                    <Col sm={8} md={7}>
                      <div className="nk-footer-info">
                        <h5 className="title">Pages</h5>
                        <ul className="row gy-1 gy-sm-4">
                          <li className="col-6">
                            <Link to="/">Home</Link>
                          </li>
                          <li className="col-6">
                            <Link to="/about">About Us</Link>
                          </li>
                          <li className="col-6">
                            <Link to="/#plans">Plans</Link>
                          </li>
                          <li className="col-6">
                            <Link to="/#faq">FAQ</Link>
                          </li>
                          <li className="col-6">
                            <Link to="/contact-us">Contact Us</Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="nk-footer-info">
                        <h5 className="title">Utility Pages</h5>
                        <ul className="row gy-1 gy-sm-4">
                          <li className="col-12">
                            <Link to="https://app.siteoptimizr.com/">Client Login</Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="nk-footer-bottom bg-gray">
            <Container>
              <Row className="nk-footer-content justify-content-between">
                <Col lg={6} className="px-0">
                  <p className="nk-footer-copyright-text text-center text-lg-start">
                    &copy; <span id="currentYear">{new Date().getFullYear()}</span>
                    <Link className={brandLinkClasses} to="https://siteoptimizr.com/" target="_blank">
                      {" "} Site OptimizR
                    </Link>. All Rights Reserved.
                  </p>
                </Col>
                <Col lg={6} className="px-0">
                  <ul className="nk-footer-copyright justify-content-center justify-content-lg-end">
                    <li>
                      <Link className={footerTextClasses} to="/terms-and-conditions">Terms & conditions</Link>
                    </li>
                    <li>
                      <Link className={footerTextClasses} to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      ) : (
        <>{children}</>
      )}
    </footer>
  );
}