import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NioButton } from './components'; // Make sure to import NioButton


// context 
import DataProvider from './context/DataProvider/DataProvider';

// landing page 
import Landing from './pages/landing';

// Homepages 
import ChatApp from './pages/home-pages/chat-app';
import KidsCourse from './pages/home-pages/kids-course';
import AnalyticsPage from './pages/home-pages/analytics';
import BusinessDigital from './pages/home-pages/business-digital';
import BusinessExpense from './pages/home-pages/business-expense';
import BusinessTracker from './pages/home-pages/business-tracker';
import BusinessSolution from './pages/home-pages/business-solution';
import CollaborationTool from './pages/home-pages/collaboration-tool';
import CryptoProfile from './pages/home-pages/crypto-profile';
import BusinessManagement from './pages/home-pages/business-management';
import DataDriven from './pages/home-pages/data-driven';
import LanguageLearning from './pages/home-pages/language-learning';
import KeywordResearchTool from './pages/inner-pages/keyword-research';
import ThanksPage from './pages/inner-pages/thanks';



// inner pages 
import Terms from './pages/inner-pages/terms';
import About from './pages/about';
import Pricing from './pages/inner-pages/pricing';
import Feature from './pages/inner-pages/features';
import Contact from './pages/contact-us';
import HelpCenter from './pages/inner-pages/help-center';
import BlogDetails from './pages/inner-pages/blog-details';
import AboutSolution from './pages/inner-pages/about-solution';
import PricingSolution from './pages/inner-pages/pricing-solution';
import ContactSolution from './pages/inner-pages/contact-solution';
import FeaturesSolution from './pages/inner-pages/features-solution';
import HelpCenterDetails from './pages/inner-pages/help-center-details';
import CustomerTestimonial from './pages/inner-pages/customer-testimonial';
import Career from './pages/inner-pages/career';
import CareerDetails from './pages/inner-pages/career-details';
import ConversionCalculator from './pages/inner-pages/conversion-calculator';
// miscellaneous pages
import NotFound from './pages/404';
import Login from './pages/auth/login';
import Signup from './pages/auth/sign-up';
import ResetPassword from './pages/auth/reset-password';
import ForgotPassword from './pages/auth/forgot-password';
import PrivacyPolicy from './pages/inner-pages/privacy-policy';
import SERPPreviewTool from './pages/inner-pages/serp-preview';

function AppRoutes() {

  return (
    <Routes>

      {/* landing page */}
      <Route path="/" element={<Landing />} />

      {/* Homepages */}
      <Route path="/index-analytics" element={<AnalyticsPage />} />
      <Route path="/index-kids-course" element={<KidsCourse />} />
      <Route path="/index-collaboration-tool" element={<CollaborationTool />} />
      <Route path="/index-bs-expense-tracker" element={<BusinessTracker />} />
      <Route path="/index-live-chat-app" element={<ChatApp />} />
      <Route path="/index-bs-solution" element={<BusinessSolution />} />
      <Route path="/index-saas" element={<BusinessExpense />} />
      <Route path="/index-bs-digital" element={<BusinessDigital />} />
      <Route path="/index-crypto-profile" element={<CryptoProfile />} />
      <Route path="/index-bs-management" element={<BusinessManagement />} />
      <Route path="/thanks" element={<ThanksPage />} />
      <Route path="/index-data-driven" element={<DataDriven />} />
      <Route path="/index-language-learning" element={<LanguageLearning />} />

      {/* inner page  */}
      <Route path='/about' element={<About />} />
      <Route path='/features' element={<Feature />} />
      <Route path='/pricing' element={<Pricing />} />
      <Route path='/contact-us' element={<Contact />} />
      <Route path='/help-center' element={<HelpCenter />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path="/blog-details/:slug" element={<BlogDetails />} /> {/* Updated to include dynamic slug */}      
      <Route path='/terms-and-conditions' element={<Terms />} />
      <Route path='/about-solution' element={<AboutSolution />} />
      <Route path='/features-solution' element={<FeaturesSolution />} />
      <Route path='/pricing-solution' element={<PricingSolution />} />
      <Route path='/contact-us-solution' element={<ContactSolution />} />
      <Route path='/help-center-details' element={<HelpCenterDetails />} />
      <Route path='/customer-testimonials' element={<CustomerTestimonial />} />
      <Route path='/careers' element={<Career />} />
      <Route path='/career-details' element={<CareerDetails />} />
      <Route path='/conversion-calculator' element={<ConversionCalculator />} />
      <Route path='/serp-preview' element={<SERPPreviewTool />} />
      <Route path='/keyword-research' element={<KeywordResearchTool />} />



      {/* miscellaneous pages */}
      <Route path="*" element={<NotFound />} />
      <Route path="/404" element={<NotFound />} />
      <Route path='/auth/login' element={<Login />} />
      <Route path='/auth/sign-up' element={<Signup />} />
      <Route path='/auth/reset-password' element={<ResetPassword />} />
      <Route path='/auth/forgot-password' element={<ForgotPassword />} />
      <Route path="/blog-details/:slug" element={<BlogDetails />} />


    </Routes>
  );
}


function App() {

  useEffect(() => {
    document.body.classList.add('nk-body');
    document.title = 'Site OptimizR';
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.body.classList.add('nk-body');
    document.title = 'Site OptimizR';

    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <DataProvider>
      <BrowserRouter>
        {isVisible && (
          <div 
            style={{
              position: 'fixed',
              right: '20px',
              bottom: '20px',
              zIndex: 1000,
              transition: 'all 0.3s ease-in-out',
            }}
          >
            {/* <a href="/contact-us">
              <NioButton
                label="Book Now"
                icon="calendar"
                className="btn-lg text-nowrap"
                style={{
                  borderRadius: '50px',
                  padding: '15px 30px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#0062FF',
                  color: 'white'
                }}
              />
            </a> */}
          </div>
        )}
        <AppRoutes />
      </BrowserRouter>
    </DataProvider>
  );
}



export default App;
