import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { HashLink as Link } from 'react-router-hash-link';

import AppLayout from '../../layouts/AppLayout/AppLayout';
import { NioSection, NioButton, NioMedia, NioCard, NioSubscribeField, NioIcon } from '../../components';
import ContactForm from '../../components/PageComponents/SectionComponents/ContactForm/ContactForm';

function ContactUsPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState('');

  return (
    <AppLayout title="Contact" rootClass="layout-1">
      <NioSection className="pt-120 pt-lg-160" masks={["blur-1 left center"]}>
        <NioSection.Head alignX="center">
          <h2>Contact Us</h2>
        </NioSection.Head>
      </NioSection>

      <NioSection masks={["blur-1 right center"]}>
        <NioSection.Content>
          <Row className="contact-custom gy-5">
            <Col lg={8}>
              <motion.div
                className="consultation-form-container"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
              >
                <h3 className="text-blue mb-3 text-center">
                  Book Your Free Consultation Now
                </h3>
                
                <div className="consultation-benefits mb-4 p-3 bg-light rounded">
                  <h5 className="text-center mb-3">In this free 10-minute call, you'll get:</h5>
                  <ul className="list-unstyled">
                    <li className="d-flex align-items-center mb-3">
                      <i className="ni ni-check-circle-fill text-success me-2"></i>
                      <span>A personalized website analysis identifying conversion blockers</span>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <i className="ni ni-check-circle-fill text-success me-2"></i>
                      <span>Actionable CRO strategies tailored to your business</span>
                    </li>
                    <li className="d-flex align-items-center mb-3">
                      <i className="ni ni-check-circle-fill text-success me-2"></i>
                      <span>Insights on how we've helped similar businesses increase revenue</span>
                    </li>
                  </ul>
                </div>
                
                <div className="text-center mb-4">
                </div>
                
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  <a 
                    href="https://scheduler.zoom.us/casey-bmq3jn/10-minute-website-audit" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn text-nowrap w-100 text-white d-flex align-items-center justify-content-center"
                    style={{
                      padding: '15px 25px',
                      fontSize: '18px',
                      backgroundColor: '#0062FF',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <i className="ni ni-calendar me-2"></i>
                    Schedule Your Free Call Now
                  </a>
                </motion.div>
                
                <div className="mt-4 text-center">
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <i className="ni ni-shield-check text-success me-2"></i>
                    <p className="mb-0 text-muted">100% free, no credit card required</p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <i className="ni ni-clock text-primary me-2"></i>
                    <p className="mb-0 text-muted">10-minute focused consultation</p>
                  </div>
                </div>
              </motion.div>
            </Col>
            <Col lg={4}>
              <div className="card-list">
                <NioCard className="rounded-2">
                  <NioCard.Body>
                    <NioMedia size="lg" rounded variant="primary-soft" icon="sign-usd" className="mb-3 mb-md-5" />
                    <h4>Plans Available</h4>
                    <p className="line-clamp-2">Choose from our flexible plans to find the best fit for your business needs.</p>
                    <div className="pt-2">
                      <Link to="/#plans" className="bg-transparent p-0 rounded-0 border-0 btn-link text-primary">
                        <span>See Plans</span>
                        <NioIcon name="arrow-right" />
                      </Link>
                    </div>
                  </NioCard.Body>
                </NioCard>
                <NioCard className="rounded-2">
                  <NioCard.Body>
                    <NioMedia size="lg" rounded variant="primary-soft" icon="question" className="mb-3 mb-md-5" />
                    <h4>Frequently Asked Questions</h4>
                    <p className="line-clamp-2">Find answers to commonly asked questions about our services in our FAQ section.</p>
                    <div className="pt-2">
                      <Link to="/#faq" className="bg-transparent p-0 rounded-0 border-0 btn-link text-primary">
                        <span>See All Questions</span>
                        <NioIcon name="arrow-right" />
                      </Link>
                    </div>
                  </NioCard.Body>
                </NioCard>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
    </AppLayout>
  );
}

export default ContactUsPage;