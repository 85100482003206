// context
import { useLayoutUpdate } from '../../../../context/LayoutProvider/LayoutProvider';

// components
import NioButton from '../../../NioButton/NioButton';
import NioIcon from '../../../NioIcon/NioIcon';

export default function HeaderAction({ buttonContent, className, nioBtnClasses, nioToggleClasses }) {
  const layoutUpdate = useLayoutUpdate();

  return (
    <div className={className} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      {/* Client Login Button (Smaller) */}
      <NioButton
        as="link"
        icon="lock"
        href="https://app.siteoptimizr.com/"
        label="Client Login"
        className={`${nioBtnClasses} btn-sm`} // Adjusts to a smaller size
        style={{ padding: '6px 12px', fontSize: '14px' }}
      />

      {/* Phone Number (Smaller) */}
      <a
        href="tel:+14074438665"
        className="header-phone-link"
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#333',
          textDecoration: 'none',
          backgroundColor: 'white',
          padding: '6px 12px',
          borderRadius: '6px',
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <NioIcon name="phone" />
        <span
          className="green-light"
          style={{
            backgroundColor: '#28a745',
            borderRadius: '50%',
            width: '8px',
            height: '8px',
            marginLeft: '6px',
          }}
        ></span>
        <span style={{ marginLeft: '6px' }}>+1 (407) 443-8665</span>
      </a>

      {/* Mobile Menu Toggle (Only Appears on Mobile) */}
      <div className="d-md-none">
        <NioButton
          icon="menu"
          className={nioToggleClasses}
          onClick={layoutUpdate.headerMobile}
        />
      </div>
    </div>
  );
}